body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.myClass {
  margin-right: 5%;
  margin-left: 5%;
  margin-top: 3%;
}

.title {
  font-size: x-large;
}

.txdetail-title {
  font-size: medium;
  font-weight: 600;
  text-align: center;
}

.alert-shown {
  opacity: 1;
  transition: all 250ms linear;
}

.alert-hidden {
  opacity: 0;
  transition: all 250ms linear 2s;
}